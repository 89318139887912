<template>
  <div style="width: 100%">
    <div :class="active == 1 ? 'body' : 'body1'">
      <div :class="active == 1 ? '' : 'toop'">
        <div
          style="display: flex; justify-content: flex-end"
          :class="active == 1 ? 'top_list' : 'top_list1'"
        >
          <div v-if="token != 0" style="margin-right: 20px; line-height: 37px">
            <p style="font-size: 18px">欢迎{{ username }}登录</p>
          </div>
          <!-- <div class="top_item">会员注册</div> -->
          <div class="top_item" @click="toLogin" v-if="token == 0">
            <p style="font-size: 18px; margin-right: -120px">会员登录</p>
          </div>
          <div class="top_item" @click="toLoginS" v-if="token == 0">
            <p style="font-size: 18px; margin-right: -65px">入会申请</p>
          </div>
          <div
            class="top_item"
            @click="toYewu"
            v-if="token == 0"
            style="font-size: 18px; margin-right: -49px"
          >
            业务申请
          </div>
          <div class="top_item" v-if="token == 0">
            <p
              style="font-size: 18px; margin-right: -40px"
              @click="toOldVersion"
              v-if="switchShow"
            >
              切换
            </p>
            <p
              style="font-size: 18px; margin-right: -40px"
              @click="toOldVersion"
              v-else
            >
              返回
            </p>
          </div>
          <div class="top_item" v-else>
            <el-dropdown style="color: white">
              <div class="el-dropdown-link" style="display: flex">
                <p style="font-size: 18px">会员服务</p>
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </div>
              <template #dropdown>
                <!-- <el-dropdown-menu v-if="token == 0">
                  <el-dropdown-item @click="fuwuVipClick('')">
                    业务申请
                  </el-dropdown-item>
                </el-dropdown-menu> -->
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="(item, index) in fuwuVip"
                    :key="index"
                    @click="fuwuVipClick(item.path)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <!-- 弹出的页面内容 -->
          <el-dialog
            v-model="dialogVisible"
            title="Tips"
            height="571"
            :before-close="handleClose"
          >
            <template #header="{ titleId, titleClass }">
              <div class="my-header">
                <div>
                  <img
                    :src="touxiang"
                    alt=""
                    style="width: 40px; height: 40px"
                  />
                </div>
                <div>
                  <h6 :id="titleId" :class="titleClass">
                    充值账号：{{ username }}
                  </h6>
                  <h6 :id="titleId" :class="titleClass">
                    {{}}vip {{}}到期，可续费
                  </h6>
                </div>
              </div>
            </template>
            <div style="display: flex; width: 729px; margin-top: -25px">
              <div class="personbody">
                <img src="../../assets/payment/personbj.png" alt="" />
                <span>个人VIP</span>
              </div>
              <div class="groupbody" style="display: flex">
                <img src="../../assets/payment/groupbj.png" alt="" />
                <span>团队VIP</span>
              </div>
            </div>
            <!-- <span style="margin-left: 20px; line-height: 40px">个人VIP</span> -->
            <!-- <span style="margin-left: 20px; line-height: 40px">团队VIP</span> -->
            <!-- <el-form :model="form">
              <el-form-item label="银行卡号" :label-width="formLabelWidth">
                <el-input v-model="form.number" autocomplete="off" disabled />
              </el-form-item>
            </el-form> -->
            <!-- <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button
                  type="primary"
                  @click="dialogVisible = false"
                  style="background-color: #cc0505"
                >
                  确认
                </el-button>
              </div>
            </template> -->
          </el-dialog>

          <div>
            <!-- <el-input
              v-model="input3"
              placeholder="请您输入要搜索的内容"
              class="input-with-select"
            >
              <template #append>
                <el-button
                  :icon="Search"
                  style="background-color: #9d1414; color: white"
                />
              </template>
            </el-input> -->
          </div>
        </div>
      </div>

      <!-- 导航栏切换图片 -->
      <div :class="active == 1 ? 'meun' : 'menu1'" style="margin: 20px auto">
        <div class="meun_picture" v-if="active == 1">
          <!-- src="../../assets/header/headerIcon2.png" -->
          <img
            src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723450933262707093.png"
            style="
              position: relative;
              right: -3px;
              top: -21px;
              width: 432px;
              height: 85.7px;
            "
            v-if="switchShow"
          />
          <!-- src="../../assets/header/headerbj1.png" -->
          <!-- src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202407/1721810348572512445.png" -->
          <!-- src="../../assets/header/header_title.png" -->
          <img
            src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723276875266179934.png"
            style="
              position: relative;
              right: -3px;
              top: -21px;
              width: 432px;
              height: 85.7px;
            "
            v-else
          />
        </div>
        <div class="meun_picture" v-else>
          <!-- margin-top: -17px; -->
          <img
            src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723450933262707093.png"
            style="
              position: relative;
              right: -6px;
              width: 434px;
              height: 86.1px;

              margin-left: 3.5px;
            "
            v-if="switchShow"
          />
          <!-- top: -21px; -->
          <img
            src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723276875266179934.png"
            style="
              position: relative;
              right: -3px;

              width: 432px;
              height: 85.7px;
            "
            v-else
          />
        </div>
        <div
          :class="active == 1 ? 'menu_body' : 'menu_body1'"
          style="margin-left: 24px; margin-top: 25px"
        >
          <div
            style="font-size: 20px"
            class="menu_item"
            v-for="item in menuList"
            :key="item.id"
            :class="item.id == active ? 'active' : ''"
            @click="activeClick(item.id)"
          >
            <el-dropdown
              :style="active == 1 ? 'color:white ' : 'color:#000'"
              v-if="item.id == 8"
            >
              <div class="el-dropdown-link">
                <div>{{ item.title }}</div>
                <!-- <div style="font-size: 10px">{{ item.name }}</div> -->
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="(item, index) in jgfenzhi"
                    :key="index"
                    @click="activeClickItem(item.id)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div v-if="item.id != 8">{{ item.title }}</div>
            <!-- <div style="font-size: 10px" v-if="item.id != 8">
              {{ item.name }}
            </div> -->
          </div>
        </div>
      </div>

      <!-- 首页轮播图 -->
      <div class="header_swiper" v-if="active == 1">
        <!-- :interval="4000" :autoplay="false"-->
        <el-carousel
          ref="carousel"
          :interval="3000"
          :pause-on-hover="true"
          :autoplay="true"
          arrow="never"
          type="card"
          height="260px"
          trigger="click"
          @change="handleCarouselChange(e)"
          :initial-index="initialIndex"
        >
          <el-carousel-item
            v-for="(item, index) in carouselList"
            :key="index"
            :name="item.title"
          >
            <!-- style="margin-top: 1px; height: 260px; object-fit: cover" -->
            <img
              :src="
                'https://bassociation.oss-cn-beijing.aliyuncs.com/' + item.path
              "
              alt=""
              style="width: 65%; height: 100%; object-fit: cover"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  nextTick,
  onMounted,
  ref,
  watch,
  watchEffect,
  onUpdated,
  reactive,
  inject,
} from "vue";
import { getJSSDK } from "@/api/modules/register.js";
import { Search, ArrowDown } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { defineProps, computed } from "vue";
import { tupian } from "@/api/modules/homer";
import { onBeforeRouteEnter } from "vue";
let touxiang = ref(require("../../assets/registerPerson/personHeader.png"));
// 获取路由实例
const router = useRouter();
router.beforeEach((to, from, next) => {
  getjsdk(to, from, next);
  next();
});
// 获取sdk
let currentURL = ref("");
let store = useStore();
// console.log(store.state);
const urlData = computed(() => store.state.urlData);
let jsonString = ref(""); //描述
let jsonName = ref(""); //名称
let getjsdk = async (to, from, next) => {
  // 判断是否是安卓设备
  const isAndroid = /android/i.test(navigator.userAgent);
  // 判断是否是iOS设备
  const isiOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
  if (isAndroid) {
    currentURL.value = "http://www.jyshxh.com" + to.fullPath;
    // console.log("这是安卓设备");
  } else if (isiOS) {
    currentURL.value = "http://www.jyshxh.com" + to.fullPath;
    // console.log("这是iOS设备");
  }
  let res = await getJSSDK({
    url: currentURL.value,
  });
  // console.log(res, "getjsdk");
  /* eslint-disable */
  wx.config({
    debug: false, // 开启调试模式
    appId: "wx138b5bfdad0e4466", // 必填，公众号的唯一标识
    timestamp: res.timestamp + "", // 必填，生成签名的时间戳
    nonceStr: res.nonceStr, // 必填，生成签名的随机串
    signature: res.signature, // 必填，签名
    jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
  });

  wx.ready(function () {
    nextTick(() => {
      if (to.fullPath == "/home/homer?active=1") {
        // 首页
        // console.log("0000000首页");
        jsonName.value = "(全国)教育书画协会官网";
        jsonString.value =
          "(全国)教育书画协会是经国务院批准，在民政部登记注册的全国性公益学术团体。";
      } else if (
        localStorage.getItem("keyString") &&
        localStorage.getItem("content")
      ) {
        // 详情
        // console.log("11111111111详情详情");
        jsonName.value = localStorage.getItem("keyString");
        var div = document.createElement("div");
        div.innerHTML = localStorage.getItem("content");
        var text = div.textContent || div.innerText || "";
        jsonString.value = text.trim().replace(/[\n\t]+/g, "");
      } else if (
        localStorage.getItem("keyString") == null &&
        localStorage.getItem("content")
      ) {
        // 点导航
        jsonName.value = "(全国)教育书画协会官网";
        jsonString.value = localStorage.getItem("content");
        // console.log("2导航导航22222");
      } else if (
        localStorage.getItem("keyString") &&
        localStorage.getItem("content") == null
      ) {
        // 虚拟展厅
        jsonName.value = localStorage.getItem("keyString");
        jsonString.value = "";
        // console.log("2虚拟展厅22222");
      }
      // 在这里调用自动分享的代码
      wx.onMenuShareAppMessage({
        title: jsonName.value, // 分享标题
        desc: jsonString.value, // 分享描述
        link: "http://www.jyshxh.com" + to.fullPath, // 分享链接，该链接必须在微信后台配置为有效域名
        imgUrl:
          "https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723614666688933889.jpg", // 分享图标
        type: "", // 分享类型,music、video或link，不填默认为link
        dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
        success: function () {
          // 用户确认分享后执行的回调函数
          console.log("分享成功");
        },
        cancel: function () {
          // 用户取消分享后执行的回调函数
          console.log("分享失败");
        },
      });
      wx.onMenuShareTimeline({
        title: jsonName.value, // 分享标题
        desc: jsonString.value, // 分享描述
        link: "http://www.jyshxh.com" + to.fullPath, // 分享链接，该链接必须在微信后台配置为有效域名
        imgUrl:
          "https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723614666688933889.jpg", // 分享图标
        success: function () {
          // 用户确认分享后执行的回调函数
          console.log("分享成功");
        },
        cancel: function () {
          // 用户取消分享后执行的回调函数
          console.log("分享失败");
        },
      });
    });
  });
};
let fetchURLData = () => {
  store.dispatch("fetchURLData");
};

let fuwuVip = ref([
  { name: "证书查询", path: "/person/zhengshuSelect" },
  { name: "业务申请", path: "/person/yewuApplyFor" },
  { name: "个人中心", path: "/person/personCenter" },
  // { name: "缴费", path: "/person/payment" },
  // { name: "缴费", path: "" },
  { name: "缴费", path: "/person/paymentPage" },
  { name: "退出登录", path: "/" },
]);

const Select_id = localStorage.getItem("Teamand_Individual");
if (Select_id) {
  const Select_id_p_g = JSON.parse(Select_id);
  if (Select_id_p_g === "个人") {
    fuwuVip.value[2].path = "/person/personCenter";
  } else {
    fuwuVip.value[2].path = "/person/groupCenter";
  }
}

let form = ref({ number: 123456 });

let jgfenzhi = ref([
  { id: 1, name: "机构列表" },
  { id: 2, name: "机构活动" },
  // { id: 3, name: "机构通知" },
]);
// let input3 = ref("");
// onMounted(() => {
//   console.log("用户输入的内容", input3);
// });
let menuList = ref([
  { id: 1, title: "首  页", name: "home" },
  { id: 2, title: "协会概况", name: "introduce" },
  { id: 8, title: "分支机构", name: "branceOffice" },
  { id: 4, title: "信息发布", name: "newsReport" },
  { id: 5, title: "虚拟展厅", name: "artExhibition" },
  { id: 6, title: "名师讲堂", name: "expertLecture" },
  { id: 9, title: "会员风采", name: "membershipStyle" },
  { id: 3, title: "政策法规", name: "policyLegislation" },
  { id: 7, title: "党建工作", name: "Partybuildingwork" },
]);
let route = useRoute();

let token = ref(null);
let username = ref(null);
let active = ref(null);
let activeClick = (e) => {
  if (e == 8) {
    let a = localStorage.getItem("active");
    active.value = a;
  } else {
    localStorage.setItem("active", e);
    active.value = e;
  }
  // if (e != 3 && e != 6 && e != 5 && e != 7) {
  //   localStorage.setItem("active", e);
  //   active.value = e;
  // }

  if (e == 1) {
    router.push({
      path: "/home/homer",
      query: { active: "1" },
    });
  } else if (e == 2) {
    router.push({
      path: "/introduceXH/intrXh",
    });
  } else if (e == 3) {
    // alert("正在建设中");
    router.push("/laws/policies");
  } else if (e == 4) {
    router.push("/news/report");
  } else if (e == 5) {
    router.push("/art/exhibition");
    // alert("正在建设中");
  } else if (e == 6) {
    // alert("正在建设中");
    router.push("/virtual/classroom");
  } else if (e == 7) {
    router.push("/party/work");
    // alert("正在建设中");
  } else if (e == 9) {
    // router.push("/membership/membershipUser");
    alert("正在建设中");
  }
};
let activeClickItem = (e) => {
  if (e == 1) {
    // console.log("跳转到机构列表");
    localStorage.setItem("active", 8);
    active.value = 8;
    router.push("/branch/officeList");
  } else if (e == 2) {
    // console.log("跳转到机构活动");
    // alert("正在建设中");
    active.value = 8;
    router.push("/branch/officeactivities");
  } else if (e == 3) {
    console.log("跳转到机构通知");
    router.push("/branch/officenotice");
  }
};
let carouselList = ref([]);
const downT = async () => {
  const res = await tupian();
  carouselList.value = res;
  console.log("wqeq", res);
};

let initialIndex = ref();
let randomindex = () => {
  initialIndex.value = Math.floor(Math.random() * 7);
  // console.log("kkkkkkkkkkkkkkkkkkkkkk", initialIndex.value);
  if (initialIndex.value === 1) {
    randomindex();
  }
};

let toLoginS = () => {
  router.push("/register/registerUser");
  // alert("建设中...");
};
let toLogin = () => {
  router.push("/login");
  // alert("建设中...");
};
let toYewu = () => {
  router.push("/person/yewuApplyFor");
  localStorage.setItem("active", 24);
  active.value = 24;
  // alert("建设中...");
};
const switchShow = inject("switchShow");
let toOldVersion = () => {
  switchShow.value = !switchShow.value;
};
let handleClose = () => {};
const dialogVisible = ref(false);
let fuwuVipClick = (path) => {
  if (path == "") {
    dialogVisible.value = true;
    console.log("输入为空", dialogVisible.value);
    // alert("建设中...");
  } else {
    if (path == "/") {
      active.value = 1;
      localStorage.setItem("token", 0);
      token.value = localStorage.getItem("token");
      localStorage.setItem("username", "");
      username.value = localStorage.getItem("username");
    } else {
      localStorage.setItem("active", 20);
      active.value = 20;
    }
    router.push(path);
  }
};
const state = reactive({
  to: {
    fullPath: route.fullPath, // 设置 to.fullPath 为当前路由的完整路径
  },
  from: window.location.href, // 设置 from 为当前页面的完整 URL
  next: () => {}, // 空的 next 函数
});
onMounted(() => {
  getjsdk(state.to, state.from, state.next);
  fetchURLData();
  downT();
  randomindex();
  active.value = localStorage.getItem("active");
  if (localStorage.getItem("token")) {
    token.value = localStorage.getItem("token");
    console.log("token", token.value);
    username.value = localStorage.getItem("username");
  } else {
    token.value = 0;
  }
  setTimeout(() => {
    if (active.value == 1) {
      let c = document.querySelector(".header_swiper"); //轮播容器
      // console.log("*/*/*/", c);
      let elCarouselItems = c.querySelector(".el-carousel__container"); //轮播主体
      // console.log(elCarouselItems, "elCarouselItems");
      if (elCarouselItems) {
        let b = document.getElementsByClassName(
          "el-carousel__item el-carousel__item--card"
        );
        // 中间图片优先级
        let unmC = initialIndex.value;
        let unmCenter = elCarouselItems.children[unmC];
        if (unmCenter) {
          unmCenter.style.zIndex = "5";

          let num = initialIndex.value + 1;
          // =====================================================================================
          //获取右侧图片
          // console.log(num,"右");
          let wR = elCarouselItems.children[num];
          let transR = wR.style.transform;
          let newXtransR = "522.7px";
          let newtransR = transR
            .replace(/translateX\([^)]+\)/, "translateX(" + newXtransR + ")")
            .replace(/scale\([^)]+\)/, "scale(1)");
          wR.style.transform = newtransR;
          wR.classList.add("is-in-stage");
          wR.style.zIndex = "3";

          //最左侧图片索引
          let numL = initialIndex.value - 1; //最左边图片索引解决页面刷新后左侧图片太小问题
          // 解决刷新衔接获取不到图片问题
          for (let j = 0; j < b.length; j++) {
            if (numL < 0) {
              numL = b.length - 1;
            }
          }
          // console.log(numL,"左");
          let wL = elCarouselItems.children[numL]; //获取左侧图片
          let transL = wL.style.transform;
          let newXtransL = "100.7px";
          let newtransL = transL
            .replace(/translateX\([^)]+\)/, "translateX(" + newXtransL + ")")
            .replace(/scale\([^)]+\)/, "scale(1)");
          wL.style.transform = newtransL;
          wL.classList.add("is-in-stage");
          wL.style.zIndex = "3";

          //最左左侧图片索引
          let numLeft = initialIndex.value - 2;
          // 解决刷新衔接获取不到图片问题
          for (let j = 0; j < b.length; j++) {
            if (numLeft < 0) {
              numLeft = b.length - 2;
            }
          }
          // console.log(numLeft,"左左");
          let wLeft = elCarouselItems.children[numLeft]; //获取最左左侧图片
          let transLeft = wLeft.style.transform;
          let newXtransLeft = "-108.7px";
          let newtransLefte = transLeft
            .replace(/translateX\([^)]+\)/, "translateX(" + newXtransLeft + ")")
            .replace(/scale\([^)]+\)/, "scale(1)");
          wLeft.style.transform = newtransLefte;
          wLeft.classList.add("is-in-stage");

          //最右右侧图片索引
          let numRight = initialIndex.value + 2;
          // 解决刷新衔接获取不到图片问题
          for (let j = 0; j < b.length; j++) {
            if (numRight > b.length - 1) {
              numRight = 0;
            }
          }
          // console.log(numRight,"右右");
          let wRight = elCarouselItems.children[numRight];
          let transformValue = wRight.style.transform;
          let newXValue = "860.3px";
          let newTransformValue = transformValue
            .replace(/translateX\([^)]+\)/, "translateX(" + newXValue + ")")
            .replace(/scale\([^)]+\)/, "scale(1)");
          wRight.style.transform = newTransformValue;
          wRight.classList.add("is-in-stage");
          // =====================================================================================

          document
            .querySelector(".el-carousel--horizontal")
            .classList.remove("el-carousel--horizontal");
        }
        // console.log(numRight, wRight, yRight, "bbbbb");
        // console.log(numLeft, wLeft, yRight, "bbbbb");
        // if (initialIndex.value > 0) {
        //   console.log(initialIndex.value,"》》0");
        //   let num1 = initialIndex.value - 1;
        //   let q = elCarouselItems.children[num1];
        //   let n = q.style.transform.replace(/scale\(\d+\.?\d*\)/, "scale(1)");
        //   q.style.transform = n;
        //   // console.log("前面的", q);
        // } else if (initialIndex.value == 0) {
        //   console.log(initialIndex.value,"==0");
        //   let length = elCarouselItems.children.length - 1;
        //   let q = elCarouselItems.children[length];
        //   let n = q.style.transform.replace(/scale\(\d+\.?\d*\)/, "scale(1)");
        //   q.style.transform = n;
        //   // console.log("前面的", q);
        // }
      }
    }
  }, 400);
  // handleCarouselChange();
  // nextTick(() => {
  // let a = document.getElementsByClassName(
  //   "el-carousel__item is-in-stage el-carousel__item--card"
  // );
  // console.log(a, "all");
  // let b = document.getElementsByClassName(
  //   "el-carousel__item is-active is-in-stage el-carousel__item--card"
  // );
  // // a[0].style.scale = 2;
  // // console.log("***********", a[0].style.scale);
  // // console.log("***********", a[0].classNameList);
  // for (let i = 0; i < a.length; i++) {
  //   // a[i].style.scale = 0.83;
  //   let x = a[i].style.transform;
  //   let y = x.replace(/scale\(\d+\.?\d*\)/, "");
  //   // let yTransfrom = x.replace(/transform\(\d+\.?\d*\)/, "translateX(90.4775px)");
  //   a[i].style.transform = y;
  //   // a[i].style.transform = yTransfrom;
  //   // b[0].style.scale = 1;
  // }
  // });
});
const carousel = ref(null);
// 点击轮播图方法
let handleCarouselChange = (e) => {
  nextTick(() => {
    let b = document.getElementsByClassName(
      "el-carousel__item el-carousel__item--card"
    );
    for (let i = 0; i < b.length; i++) {
      // 清除赋值过的z-index=5
      if (
        b[i].style.zIndex === "5" &&
        b[i].className ==
          "el-carousel__item is-in-stage el-carousel__item--card"
      ) {
        b[i].style.zIndex = "1";
      }
      // 解决点击左侧优先级问题
      if (
        b[i].className ==
        "el-carousel__item is-in-stage el-carousel__item--card"
      ) {
        b[i].style.zIndex = "1";
      }

      if (
        b[i].className ==
        "el-carousel__item is-active is-in-stage el-carousel__item--card"
      ) {
        // 解决衔接显示bug
        let numC = i;
        if (numC == b.length - 1) {
          let items = document.querySelectorAll(".el-carousel__item");
          items.forEach((item) => {
            item.classList.remove("cLeft", "transLeft", "transRight", "transR");
          });
          let numCenter = b[numC];
          // 中间图片优先级
          numCenter.style.zIndex = "5";
          // console.log("shangmian");
          // console.log(numC,"center");
          let cR = b[0];
          cR.classList.add("transR");
          let cRight = b[1];
          cRight.classList.add("transRight");
          let cL = b[b.length - 2];
          cL.classList.add("cL");
          let cLeft = b[b.length - 3];
          cLeft.classList.add("cLeft");
        } else {
          let items = document.querySelectorAll(".el-carousel__item");
          items.forEach((item) => {
            item.classList.remove("cLeft", "transLeft", "transRight", "transR");
          });
          // console.log("xiamian");
          let numCenter = b[numC];
          // 中间图片优先级
          numCenter.style.zIndex = "5";

          // 获取右右张索引
          let num = i + 1; //        6 7 0
          let numRight = num + 1; // 7 0
          if (numRight >= b.length) {
            // console.log("zou");
            numRight = 0;
            b[numRight].classList.add("transRight");
          }
          // console.log(num,"右右右1111");
          // console.log(numRight,"右右右2222");
          let wRight = b[numRight];
          wRight.classList.add("transRight");

          // 获取右侧索引
          let wR = b[num];
          if (wR.style.zIndex === "5") {
            wR.style.zIndex = "3";
          }
          wR.classList.add("transR");
          wR.classList.add("is-in-stage");

          //最左侧图片索引
          let numL = i - 1;
          if (numL < 0) {
            numL = b.length - 1;
          }
          // console.log(numL,"左11111");
          let wL = b[numL]; //获取左侧图片
          if (wL.style.zIndex === "5") {
            wL.style.zIndex === "1";
          }
          let transL = wL.style.transform;
          let newXtransL = "102.7px";
          let newtransL = transL
            .replace(/translateX\([^)]+\)/, "translateX(" + newXtransL + ")")
            .replace(/scale\([^)]+\)/, "scale(1)");
          wL.style.transform = newtransL;

          //最左左侧图片索引 67012  70123
          let numLeft = i - 2;
          if (numLeft < 0) {
            numLeft = b.length - 2;
          }
          if (numC == "1") {
            numLeft = b.length - 1;
          }
          // console.log(numLeft,"左左2222");
          let wLeft = b[numLeft]; //获取最左左侧图片
          if (wLeft.style.zIndex === "5") {
            wLeft.style.zIndex = "1";
          }
          let transLeft = wL.style.transform;
          let newXtransLeft = "-108.7px";
          let newtransLeft = transLeft
            .replace(/translateX\([^)]+\)/, "translateX(" + newXtransLeft + ")")
            .replace(/scale\([^)]+\)/, "scale(1)");
          wLeft.style.transform = newtransLeft;
          wLeft.style.zIndex = 0;
        }
      }
    }

    let a = document.getElementsByClassName(
      "el-carousel__item is-in-stage el-carousel__item--card"
    );
    for (let i = 0; i < a.length; i++) {
      let x = a[i].style.transform;
      let y = x.replace(/scale\(\d+\.?\d*\)/, "scale(1)");
      a[i].style.transform = y;
      // console.log(a[i].children[1].style.height);
      // 解决最后一张和第一张衔接的高度bug
      if (a[i].children[1].style.height == "80%") {
        a[i].children[1].style.height = "100%";
        a[i].children[1].style.marginTop = null;
      }
    }

    // c.style.setProperty("transform", `${c.style.transform}`, "important");
    // let d = document.querySelector(".el-carousel__item:nth-child(2)");
    // // console.log(d, "elCarouselItems");
    // d.style.setProperty("transform", `${d.style.transform}`, "important");
    // for (let j = 0; j < c.length; j++) {
    //   if (j == 1 || j == 11) {
    //     let currentTransform = c[j].style.transform;
    //     c[j].style.transform = `${currentTransform} !important`;
    //     console.log("///////****", c[j].style.transform);
    //   }
    // }
  }, 1000);
};
watch(
  () => route.path,
  (newVal, oldVal) => {
    console.log(newVal);
    // console.log("路由变化了", newVal, oldVal, route);
    if (newVal == "/news/report") {
      active.value = 4;
    } else if (newVal == "/art/exhibition") {
      active.value = 5;
    } else if (newVal == "/virtual/classroom") {
      active.value = 6;
    } else if (newVal == "/laws/policies") {
      active.value = 3;
    } else if (newVal == "/news/reportMessage") {
      console.log("详情");
      active.value = 4;
    } else if (newVal == "/home/homer") {
      console.log("这里是active详情");
      active.value = 1;
      handleCarouselChange();
      localStorage.setItem("active", 1);
    } else if (newVal == "/party/workmsg") {
      console.log("详情");
      active.value = 7;
      localStorage.setItem("active", 7);
    } else if (newVal == "/art/exhibitionMessage") {
      console.log("详情");
      active.value = 5;
      localStorage.setItem("active", 5);
    } else if (newVal == "/laws/policiesMessage") {
      console.log("详情");
      active.value = 3;
      localStorage.setItem("active", 3);
    }

    // else if (newVal == "/membership/membershipPerson") {
    //   console.log("详情");
    //   active.value = 9;
    //   localStorage.setItem("active", 9);
    // }
  }
);
</script>

<style scoped>
.cL {
  transform: translateX(102.7px) scale(1) !important;
  z-index: 1 !important;
}
.cLeft {
  transform: translateX(-109.3px) scale(1) !important;
  z-index: 1 !important;
}
.transRight {
  transform: translateX(762.7px) scale(1) !important;
  z-index: 1 !important;
}
.transR {
  transform: translateX(522.7px) scale(1) !important;
  z-index: 2 !important;
}
.el-carousel__container {
  height: 260px;
  width: 1000px;
  margin-left: 60px;
}

.el-carousel__mask {
  width: 100%;
}

.body {
  width: 100%;
  height: 452px;
  /* background-image: url("../../assets/header1.png"); */
  /* jun----666 */
  background-image: url("https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202407/1721810668990883980.png");
  background-size: cover;
}

.body1 {
  width: 100%;
  height: 200px;
  /* border: 1px solid black; */
}

/* 手机端 */
@media (max-width: 1300px) {
  .body1 {
    width: 1300px;
  }

  .body {
    width: 1300px;
    height: 440px;
    /* 
    ../../assets/header1.png郝世俊
    */
    background-image: url("https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202407/1721810668990883980.png");
    background-size: cover;
  }

  .top_list {
    position: relative;
    right: 10px;
  }

  .top_list1 {
    position: relative;
    right: 10px;
  }
}

/* 电脑端 */
@media (min-width: 1236px) {
  .body1 {
    width: 100%;
  }
}

.toop {
  width: 100%;
  /* width: 1236px;
  margin: 0 auto; */
  height: 55px;
  background-color: #cc0505;
}

.top_list {
  color: white;
  /* float: right;
  margin-right: 17.5%;
  margin-top: 20px; */
  width: 1300px;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.top_list1 {
  color: white;
  /*float: right;
  margin-right: 17.5%;
  margin-top: 10px; */
  width: 1300px;
  margin: 0 auto;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.top_item {
  width: 110px;
  margin-right: 20px;
  line-height: 37px;
}

.top_item:hover {
  cursor: pointer;
}

.example-showcase .el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

:deep .is-focus {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
    inset !important;
}

:deep(:focus-visible) {
  outline: none;
}

:deep .el-dropdown {
  line-height: unset !important;
  font-size: unset !important;
}

.el-icon--right {
  top: 9px;
  right: 0px;
  font-size: 17px;
}

.meun {
  margin: 0 auto;
  clear: right;
  width: 1275px;
  height: 75px;
  /* border: 1px solid black; */
  background-image: url("../../assets/headerbj2.png");
  background-size: auto 80px;
  position: relative;
  top: 16px;
  /* margin: 20px auto; */
  display: flex;
}

.menu1 {
  margin: 0 auto;
  clear: right;
  width: 1288px;
  height: 75px;
  position: relative;
  top: 14px;
  display: flex;
  color: black;
}

.menu_body {
  /* width: 60%; */
  display: flex;
  color: white;
}

.menu_body1 {
  /* width: 60%; */
  display: flex;
  color: black;
}

.menu_item {
  margin-left: 8px;
  /* line-height: 73px; */
  width: 80px !important;
  text-align: center;
  font-weight: bold;
}

.menu_item:hover {
  cursor: pointer;
}

/* .menu_item:first-child {
  margin-left: 49px;
} */

.active {
  border-bottom: 5px solid #cc0505;
}

.el-carousel__item {
  position: absolute;
  top: 0;
  /* left: -36px; */
  height: 100%;
  display: inline-block;
  overflow: hidden;
}
/* width: 100%; */

:deep(.el-carousel__mask) {
  position: absolute;
  width: 403.5px !important;
  height: 100%;
  top: 0px;
  left: 108px;
  background-color: var(--el-color-white);
  opacity: 0.5 !important;
  transition: var(--el-transition-duration-fast);
}

.header_swiper {
  width: 1240px;
  overflow: hidden;
  margin: 20px auto;
  position: relative;
  top: 20px;
}

/* :deep(.el-carousel .el-carousel__indicators--horizontal) {
  bottom: 10px;
} */

/* 指示灯未选中的样式 */
/* :deep(.el-carousel__indicator--horizontal .el-carousel__button) {
  width: 8px;
  height: 8px;
  border-radius: 31px;
  background: #161515;
} */

/* 指示灯选中的样式 */
/* :deep(.el-carousel__indicator--horizontal.is-active .el-carousel__button) {
  width: 8px;
  height: 8px;
  border-radius: 31px;
  background: #cc0505;
} */

/* el-carousel__item is-in-stage el-carousel__item--card */

/* 弹窗样式 */
::v-deep .el-overlay {
  background: url("../../assets/login/loginbg.png") !important;
  background-size: 100% 100vh !important;
  /* opacity: 0.8; */
}

::v-deep .el-dialog {
  background-color: rgba(255, 0, 0, 0);
  color: #ffffff;
  background-image: url("../../assets/payment/bj.png") !important;
  background-size: 100% 100%;
  width: 729px;
  height: 428.25px;
  margin: 22vh auto !important;
}

/* 将el-dialog__header、el-dialog__body、el-dialog__footer背景设置为透明```css */
::v-deep .el-dialog__header {
  padding-top: 10px !important;
  background-color: rgb(255, 255, 255, 0);
  border-radius: 14px 14px 0 0;
}

::v-deep .el-dialog__title {
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  margin-left: 20px;
}

.personbody img {
  /* background-image: url("../../assets/payment/personbj.png") !important;
  background-size: 101% 100%; */
  width: 757px;
  height: 380px;
  margin-left: -30px;
  position: relative;
  z-index: 999;
}

.personbody span {
  margin-left: -685px;
  line-height: 35px;
  position: absolute;
  font-size: 17px;
  margin-top: 5px;
  z-index: 999;
}

.groupbody img {
  /* background-image: url("../../assets/payment/groupbj.png") !important;
  background-size: 101% 100%; */
  width: 752px;
  height: 375px;
  margin-left: -755px;
  margin-top: 3px;
  position: absolute;
}

.groupbody span {
  margin-left: -292px;
  line-height: 35px;
  position: absolute;
  font-size: 17px;
  margin-top: 5px;
}

/* ::v-deep .el-dialog__body {
  border-top: 0 !important;
  background-color: rgba(19, 31, 59, 0);
  background-image: url("../../assets/payment/personbj.png") !important;
  background-image: url("../../assets/payment/groupbj.png") !important;
  color: #000000;
  background-size: 101% 100%;
  height: 375px;
  padding: 0;
  text-align: left;
}

::v-deep .el-dialog__body2 {
  border-top: 0 !important;
  background-color: rgba(19, 31, 59, 0);
  background-image: url("../../assets/payment/personbj.png") !important;
  background-image: url("../../assets/payment/groupbj.png") !important;
  color: #000000;
  background-size: 101% 100%;
  height: 375px;
  padding: 0;
  text-align: left;
} */
::v-deep .el-dialog__footer {
  text-align: right;
  background-color: rgba(255, 255, 255, 0);
  margin-top: -80px;
}

::v-deep .el-row {
  top: -45px;
  left: 74px;
}

.el-form-item {
  display: flex;
  font-size: 14px;
  width: 80%;
  margin-left: 20px;
  margin-top: 50px;
}

.my-header {
  display: flex;
  height: 40px;
}

/* .el-carousel__item:nth-child(2) {
  transform: translateX(670.53px) scale(1) !important;
} */

/* .el-carousel__item:last-child {
  transform: translateX(-52.53px) scale(1) !important;
} */

/* ::v-deep .el-carousel__item:nth-child(4) .el-carousel__mask {
  margin-left: -13px;
} */
</style>
